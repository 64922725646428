import { AxiosResponse } from "axios"
import apiConfig from "./config"

export interface Message {
  name: string
  email: string
  message: string
}

const sendContactMessage = (message: Message): Promise<AxiosResponse<any>> => {
  return apiConfig.post("/contacts", message)
}

export default sendContactMessage
