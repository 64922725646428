import React from "react"
import styled from "styled-components"

const Text = styled.p`
  margin-bottom: 2rem;
`

interface Props {
  children: React.ReactNode
}

const FormError = ({ children }: Props) => {
  return <Text>{children}</Text>
}

export default FormError
