import React from "react"
import { Field } from "react-final-form"

import { fieldNames } from "../../../../constants/contact"
import { getInputRenderer } from "./inputRenderer"

export default function Email() {
  const render = getInputRenderer({
    placeholder: "Email",
    type: "email",
  })
  return <Field name={fieldNames.email} render={render} />
}
