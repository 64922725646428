import { PostPreview } from "../types/api/Post"
import Service from "../types/api/Service"

export const getPostLink = (slug: PostPreview["slug"]): string =>
  `/blog/${slug}`

export const getAnchorLink = (id: string): string => `#${id}`

export const getServiceLink = (slug: Service["slug"]): string =>
  `/services/${slug}`
