import styled, { css } from "styled-components"

export const h3 = css`
  font-size: 1.8rem;
  font-weight: 500;
  padding: 0 0 1rem 0;
  &::after {
    content: "";
    display: block;
    width: 50px;
    padding-top: 3px;
    border-bottom: 2px solid #1e8190;
  }
`

export const h4 = css`
  padding: 1rem 0 0 0;
  font-size: 1.3rem;
  font-weight: 500;
`

export const h5 = css`
  padding: 1rem 0 0 0;
  font-size: 1.15rem;
  font-weight: 500;
`

export const h6 = css`
  padding: 1rem 0 0 0;
  font-size: 1rem;
  font-weight: 500;
`

interface TextProps {
  center?: boolean
}

interface HeadingProps extends TextProps {
  showUnderline?: boolean
}

const getUnderlineStyles = ({ showUnderline = true }: HeadingProps) => {
  return (
    !showUnderline &&
    css`
      &::after {
        display: none;
      }
    `
  )
}

export const Heading = styled.h3<HeadingProps>`
  ${h3}
  ${props =>
    props.center &&
    css`
      text-align: center;
      &::after {
        margin: 0 auto;
      }
    `};
  ${getUnderlineStyles}
`

export const SubHeading = styled.h4<TextProps>`
  ${h4}
  ${props =>
    props.center &&
    css`
      text-align: center;
    `};
`

export const SubSubHeading = styled.h5<TextProps>`
  ${h5}
  ${props =>
    props.center &&
    css`
      text-align: center;
    `};
`

export const SubSubSubHeading = styled.h6<TextProps>`
  ${h6}
  ${props =>
    props.center &&
    css`
      text-align: center;
    `};
`
