import React, { useState } from "react"
import styled from "styled-components"

import { SubmenuItemInfo, SubmenuState, ToggleSubmenuButtonText } from "./types"
import BaseToggleSubmenu from "./ToggleSubmenu"
import Content from "./Content"

interface Props {
  items: SubmenuItemInfo[]
  buttonText: ToggleSubmenuButtonText
  ToggleSubmenu?: typeof BaseToggleSubmenu
}

const ListItem = styled.li`
  position: relative;
  overflow: visible !important;
`

export default function Submenu({
  items,
  buttonText,
  ToggleSubmenu = BaseToggleSubmenu,
}: Props) {
  const [isOpen, setIsOpen] = useState<SubmenuState["isOpen"]>(false)

  const open: SubmenuState["open"] = () => {
    setIsOpen(true)
  }

  const close: SubmenuState["close"] = () => {
    setIsOpen(false)
  }

  return (
    <ListItem>
      <ToggleSubmenu
        open={open}
        close={close}
        text={buttonText}
        isOpen={isOpen}
      />
      {isOpen && (
        <Content items={items} open={open} close={close} isOpen={isOpen} />
      )}
    </ListItem>
  )
}
