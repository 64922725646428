import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import { getImage } from "gatsby-plugin-image"
import { convertToBgImage } from "gbimage-bridge"
import { FluidObject } from "gatsby-image"

import Thumbnail from "../types/api/Thumbnail"

export interface BaseProps {
  image?: string
  fluid?: FluidObject
  backgroundImageData?: Thumbnail
  className?: string
}

interface Props extends BaseProps {
  children?: React.ReactNode
}

const HeroBackgroundImage = ({
  image,
  fluid,
  backgroundImageData = null,
  children = null,
  ...other
}: Props) => {
  const { helixbridge, fire, advice, creativity, matrix, privacy, legal } =
    useStaticQuery(graphql`
      query {
        helixbridge: file(relativePath: { eq: "helixbridge.jpg" }) {
          childImageSharp {
            fluid(quality: 75, maxWidth: 1024) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        fire: file(relativePath: { eq: "fire.jpg" }) {
          childImageSharp {
            fluid(quality: 75, maxWidth: 1024) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        advice: file(relativePath: { eq: "advice.jpg" }) {
          childImageSharp {
            fluid(quality: 75, maxWidth: 1024) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        creativity: file(relativePath: { eq: "creativity.jpg" }) {
          childImageSharp {
            fluid(quality: 75, maxWidth: 1024) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        matrix: file(relativePath: { eq: "matrix.jpg" }) {
          childImageSharp {
            fluid(quality: 75, maxWidth: 1024) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        privacy: file(relativePath: { eq: "privacy.jpg" }) {
          childImageSharp {
            fluid(quality: 75, maxWidth: 1024) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        legal: file(relativePath: { eq: "legal.jpg" }) {
          childImageSharp {
            fluid(quality: 75, maxWidth: 1024) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `)
  var bgimg
  if (image === "creativity") {
    bgimg = creativity.childImageSharp.fluid
  } else if (image === "fire") {
    bgimg = fire.childImageSharp.fluid
  } else if (image === "advice") {
    bgimg = advice.childImageSharp.fluid
  } else if (image === "legal") {
    bgimg = legal.childImageSharp.fluid
  } else if (image === "matrix") {
    bgimg = matrix.childImageSharp.fluid
  } else if (image === "privacy") {
    bgimg = privacy.childImageSharp.fluid
  } else if (image === "helixbridge") {
    bgimg = helixbridge.childImageSharp.fluid
  } else {
    bgimg = fluid
  }

  const getImageFromBackgroundImage = () =>
    getImage(backgroundImageData.localFile)

  const getBackgroundImageProps = () => {
    if (!backgroundImageData) return {}
    const image = getImageFromBackgroundImage()
    return convertToBgImage(image)
  }

  const backgroundImageProps = getBackgroundImageProps()

  return (
    <BackgroundImage
      Tag="section"
      fluid={bgimg}
      preserveStackingContext
      {...backgroundImageProps}
      {...other}
    >
      {children}
    </BackgroundImage>
  )
}

export default HeroBackgroundImage
