import React from "react"
import styled from "styled-components"

import PrimaryButton from "../../PrimaryButton"

const Container = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 2rem;
`

const SubmitButton = () => {
  return (
    <Container>
      <PrimaryButton type="submit">Send</PrimaryButton>
    </Container>
  )
}

export default SubmitButton
