import React from "react"
import { FieldRenderProps } from "react-final-form"
import styled from "styled-components"
import { theme } from "../../../../constants/theme"

const Container = styled.div`
  & + & {
    margin-top: 1.75rem;
  }
`

const inputStyles = `
  border-radius: 0.9375rem;
  background-color: ${theme.colors.secondary};
  color: black;
  padding: 1.125rem 1.6875rem;
  width: 100%;
  border: 0;
  font-family: inherit;
  font-size: 1rem;

  &:placeholder {
    color: "#707070";
  }
`

const Input = styled.input`
  ${inputStyles}
`

const TextArea = styled.textarea`
  ${inputStyles}
`

const Error = styled.p`
  color: red;
`

export interface BaseProps {
  placeholder: string
  type: string
  component?: "textarea" | "normal"
}

interface Props extends BaseProps, FieldRenderProps<string> {}

export default function TextInput({
  input,
  placeholder,
  type,
  component = "normal",
  meta: { touched, error },
}: Props) {
  const renderInput = (): JSX.Element => {
    const commonProps = { ...input, placeholder }
    if (component === "textarea") {
      return <TextArea {...commonProps} rows={8} />
    }
    return <Input {...commonProps} type={type} />
  }

  const renderedInput: JSX.Element = renderInput()

  const showError: boolean = touched && error

  return (
    <Container>
      {renderedInput}
      {showError && <Error>{error}</Error>}
    </Container>
  )
}
