import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

import { Heading } from "../components/styles"

import IconFacebook from "../images/fa-facebook.svg"
import IconMail from "../images/fa-envelope.svg"
import IconTwitter from "../images/fa-twitter.svg"
import IconInstagram from "../images/fa-instagram.svg"
import IconWhatsapp from "../images/fa-whatsapp.svg"
import { links } from "../constants/links"

const Foot = styled.footer`
  background: #353535;
  color: #ffffff;
`

const FlexList = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: flexStart;
`

const Container = styled.div`
  text-align: left;
  flex-basis: 33.33%;
  position: relative;
  padding: 4em;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: top;
  @media (max-width: 768px) {
    flex-basis: 50%;
  }
  @media (max-width: 640px) {
    flex-basis: 100%;
  }
`

const SocialList = styled.div`
  display: inline;
  padding-bottom: 2em;
  a {
    padding-right: 1em;
    color: #4f5356;
    :hover {
      color: #d2d2d2;
    }
  }
`

const StyledLink = styled(Link)`
  color: #fff;
  text-decoration: none;
`

const BottomStyledLink = styled(StyledLink)`
  padding-bottom: 2rem;
`

const ExpFooter = () => (
  <Foot>
    <FlexList>
      <Container>
        <Heading style={{ color: `#ffffff`, fontSize: `1.5em` }}>
          Connect with us
        </Heading>
        <SocialList>
          <a href="mailto:info@hanso.group">
            <IconMail style={{ width: `1.7em` }} />
          </a>
          <Link to="https://facebook.com/thehansogroup">
            <IconFacebook style={{ width: `1.7em` }} />
          </Link>
          <Link to="https://instagram.com/thehansogroup">
            <IconInstagram style={{ width: `1.7em` }} />
          </Link>
          <Link to="https://twitter.com/thehansogroup">
            <IconTwitter style={{ width: `1.7em` }} />
          </Link>
          <Link to="https://wa.me/+6588908896">
            <IconWhatsapp style={{ width: `1.7em` }} />
          </Link>
        </SocialList>
        <Heading style={{ color: `#ffffff`, fontSize: `1.5em` }}>
          Contact Details
        </Heading>
        <p style={{ color: `#ffffff` }}>
          Hanso Pte. Ltd.
          <br />
          30 Petain Road
          <br />
          Singapore 208099
        </p>
      </Container>
      <Container>
        <img
          src="/hansogroup-logo-square-light.svg"
          alt="Hanso Group"
          style={{
            textAlign: `center`,
            height: `300px`,
          }}
        />
      </Container>
      <Container>
        <Heading style={{ color: `#ffffff`, fontSize: `1.5em` }}>Posts</Heading>
        <BottomStyledLink id="blog" to={links.blog}>
          Blog
        </BottomStyledLink>
        <Heading style={{ color: `#ffffff`, fontSize: `1.5em` }}>Legal</Heading>
        <StyledLink id="legal" to="/legal">
          Legal Notice
        </StyledLink>
        <StyledLink id="privacy" to="/privacy">
          Privacy
        </StyledLink>
      </Container>
    </FlexList>
    <p style={{ color: `#ffffff`, textAlign: `center`, fontSize: `.8em` }}>
      Copyright 2023 by Hanso Pte. Ltd. – All rights reserved.
    </p>
  </Foot>
)

export default ExpFooter
