import React from "react"

import SubmenuLink from "./SubmenuLink"
import { SubmenuItemInfo, SubmenuState } from "../types"
import styled from "styled-components"
import { itemPadding } from "../styles"

const List = styled.ul`
  padding: 1.5rem !important;
  background-color: white;
  border-radius: 0.375rem;
  position: absolute;
  top: calc(100% - ${itemPadding});
  right: 0;
  z-index: 1;
  box-shadow: 4px 6px 10px rgba(0, 0, 0, 0.05);
  width: 100%;

  @media (min-width: 58em) {
    min-width: 17rem;
  }
`

interface Props extends SubmenuState {
  items: SubmenuItemInfo[]
}

export default function Content({ items, close }: Props) {
  const renderItem = (item: SubmenuItemInfo, index: number): JSX.Element => {
    return <SubmenuLink {...item} key={index} close={close} />
  }

  const renderedItems: JSX.Element[] = items.map(renderItem)

  return <List onMouseLeave={close}>{renderedItems}</List>
}
