import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"

import CookieConsent from "react-cookie-consent"

import Header, { Props as HeaderProps } from "./header"
import Footer from "./footer"
import Contact from "./Contact"

import "./layout.css"

interface Props {
  HeaderProps?: HeaderProps
  children: React.ReactNode
}

const Layout = ({ children, HeaderProps = {} }: Props) => {
  return (
    <>
      <CookieConsent
        location="bottom"
        buttonText="Accept"
        declineButtonText="Decline"
        buttonStyle={{ background: `white`, fontSize: `1rem` }}
        cookieName="gatsby-gdpr-google-analytics"
      >
        This site uses cookies to improve your experience. Please refer to the{" "}
        <Link id="privacy" to="/privacy/" style={{ color: `white` }}>
          Privacy Statement
        </Link>{" "}
        for details.
      </CookieConsent>
      <Header {...HeaderProps} />
      <main>{children}</main>
      <Contact />
      <Footer />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
