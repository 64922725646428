import { css } from "styled-components"
import breakpoints from "../../../constants/breakpoints"

export const itemPadding: string = "1.25rem"

export const navTextStyles = css`
  display: block;
  padding: ${itemPadding};
  text-decoration: none;
  color: white;
  line-height: 1.6;

  @media (min-width: 58rem) {
    padding: 35px 25px;
  }

  &.dark {
    color: #353535;
  }
`
