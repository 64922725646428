import styled, { css } from "styled-components"

import Button from "./Button"

export const styles = css`
  border-radius: 0.75rem;
  background-color: #000000ce;
  color: white;
  padding: 1rem 3rem;
`

const PrimaryButton = styled(Button)`
  ${styles}
`

export default PrimaryButton
