import { createBreakpoint } from "styled-components-breakpoint"

const breakpointValues = {
  xs: 0,
  sm: 425,
  md: 768,
  lg: 1280,
  xl: 1440,
}

export const breakpoints = createBreakpoint(breakpointValues)

export default breakpoints
