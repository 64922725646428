import styled, { css } from "styled-components"

export const styles = css`
  border-radius: 0.3125rem;
  border: 0;
  font-weight: 500;
  font-size: 1rem;
  cursor: pointer;
  font-family: Rubik, sans-serif;
`

const Button = styled.button`
  ${styles}
`

export default Button
