import React, { useEffect, useState } from "react"
import { Link } from "gatsby"
import styled, { css } from "styled-components"

import Services from "./Services"
import Logo from "./Logo"
import { HeadProps, IsDown } from "./types"

const navIconClass: string = "navicon"

const getStylesFromProps = ({
  color: initialColor = "white",
  isOpen,
  isLightTheme,
}: HeadProps) => {
  const backgroundColor: string = isLightTheme ? "white" : "transparent"
  const color = isLightTheme ? "black" : initialColor
  const flexDirection: string = isOpen ? "column" : "row"
  const alignItems: string = isOpen ? "flex-start" : "center"

  return css`
    background-color: ${backgroundColor};
    flex-direction: ${flexDirection};
    align-items: ${alignItems};

    & li a,
    & li button {
      color: ${color};
    }

    & .${navIconClass}, & .${navIconClass}::after, & .${navIconClass}::before {
      background-color: ${color};
    }
  `
}

const Head = styled.header<HeadProps>`
  position: fixed;
  width: 100%;
  z-index: 3;
  padding: 0px 10px;
  transition: 0.5s;
  display: flex;
  justify-content: space-between;
  ${getStylesFromProps}
`

interface MenuProps {
  isOpen: boolean
}

const getMenuStyles = ({ isOpen }: MenuProps) => {
  const width: string = isOpen ? "100%" : "auto"

  return css`
    width: ${width};
  `
}

const Menu = styled.ul<MenuProps>`
  overflow: visible !important;
  width: 100%;
  ${getMenuStyles}
`

export interface Props extends Omit<HeadProps, "isOpen"> {}

const Header = ({ color }: Props) => {
  const [isOpen, setIsOpen] = useState<HeadProps["isOpen"]>(false)
  const [isDown, setIsDown] = useState<IsDown>(false)

  const toggleIsOpen = (): void => {
    setIsOpen(!isOpen)
  }

  const handleScroll = (): void => {
    const { scrollTop } = document.documentElement
    if (scrollTop > 150) {
      setIsDown(true)
    } else if (scrollTop < 50) {
      setIsDown(false)
    }
  }

  const removeListener = (): void => {
    window.removeEventListener("scroll", handleScroll)
  }

  useEffect(() => {
    window.addEventListener("scroll", handleScroll)
    return removeListener
  }, [])

  const isLightTheme: boolean = color === "black" || isOpen || isDown

  const getClassName = (): string => {
    const baseClassName: string = "header"
    if (isLightTheme) {
      // The icons should be dark
      return `${baseClassName} dark`
    }
    return baseClassName
  }

  return (
    <Head
      id="header"
      className={getClassName()}
      color={color}
      isOpen={isOpen}
      isLightTheme={isLightTheme}
    >
      <Logo isLightTheme={isLightTheme} />
      <input
        className="menu-btn"
        type="checkbox"
        id="menu-btn"
        aria-label="MenuButton"
        onChange={toggleIsOpen}
        checked={isOpen}
      />
      <label className="menu-icon" htmlFor="menu-btn" aria-label="MenuIcon">
        <span className={navIconClass}></span>
      </label>
      <Menu className="menu" isOpen={isOpen}>
        <li>
          <Link id="group" to="/">
            Group
          </Link>
        </li>
        <li>
          <Link id="approach" to="/approach/">
            Approach
          </Link>
        </li>
        <Services />
      </Menu>
    </Head>
  )
}

export default Header
