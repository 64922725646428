import { Link } from "gatsby"
import React from "react"
import styled from "styled-components"

interface LinkProps {
  isLightTheme: boolean
}

const paddingY: string = "0.9375rem"

const StyledLink = styled(Link)<LinkProps>`
  display: block;
  float: left;
  padding: ${paddingY} 5px ${paddingY} 15px;
  text-decoration: none;
  img {
    height: 50px;
  }
`

interface Props extends LinkProps {}

export default function Logo({ isLightTheme }: Props) {
  const getLogoSrc = (): string => {
    if (isLightTheme) {
      return "/hansogroup-logo-horizontal-dark.svg"
    }
    return "/hansogroup-logo-horizontal-light.svg"
  }

  const src: string = getLogoSrc()

  return (
    <StyledLink to="/" isLightTheme={isLightTheme}>
      <img src={src} alt="Hanso Logo" id="logo" />
    </StyledLink>
  )
}
