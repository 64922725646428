import styled from "styled-components"
import { theme } from "../constants/theme"

interface Props {
  alternate?: boolean
  center?: boolean
}

const ContentArea = styled.div<Props>`
  background: ${props =>
    props.alternate ? theme.colors.secondary : "#ffffff"};
  text-align: ${props => (props.center ? "center" : "left")};
  min-height: 20rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

export default ContentArea
