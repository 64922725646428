import React from "react"
import { Field } from "react-final-form"

import { fieldNames } from "../../../../constants/contact"
import { getInputRenderer } from "./inputRenderer"

export default function MessageField() {
  const render = getInputRenderer({
    placeholder: "Message",
    type: "text",
    component: "textarea",
  })
  return <Field name={fieldNames.message} render={render} />
}
