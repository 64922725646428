import React from "react"
import styled from "styled-components"
import breakpoints from "../constants/breakpoints"

import HeroBackgroundImage, { BaseProps } from "./HeroBackgroundImage"
import PageContainer from "./PageContainer"

interface Props extends BaseProps {
  title: string
}

const Title = styled.h2`
  padding: 16rem 0;
  text-align: center;
  color: white;
  font-size: 2.5rem;

  ${breakpoints("sm")`
    font-size: 3rem;
  `}
`

const Hero = ({ title, ...other }: Props) => {
  return (
    <HeroBackgroundImage {...other}>
      <PageContainer>
        <Title>{title}</Title>
      </PageContainer>
    </HeroBackgroundImage>
  )
}

export default Hero
