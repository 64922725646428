import React from "react"
import { Field } from "react-final-form"

import { fieldNames } from "../../../../constants/contact"
import { getInputRenderer } from "./inputRenderer"

export default function Name() {
  const render = getInputRenderer({
    placeholder: "Name",
    type: "text",
  })
  return <Field name={fieldNames.name} render={render} />
}
