import styled from "styled-components"

const marginX: string = "auto"

const PageContainer = styled.div`
  padding: 0 2rem;
  max-width: 52.8125rem;
  margin-left: ${marginX};
  margin-right: ${marginX};
`

export default PageContainer
