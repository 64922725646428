import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import Submenu from "./Submenu"
import Service from "../../types/api/Service"
import QueryWithNodes from "../../types/api/QueryWithNodes"
import { SubmenuItemInfo } from "./Submenu/types"
import { getServiceLink } from "../../utils/links"

interface ServiceInfo {
  slug: Service["slug"]
  hero: Service["hero"]
}

interface Query {
  allStrapiServices: QueryWithNodes<ServiceInfo>
}

export default function Services() {
  const {
    allStrapiServices: { nodes: services },
  }: Query = useStaticQuery(graphql`
    {
      allStrapiServices {
        nodes {
          slug
          hero {
            title
          }
        }
      }
    }
  `)

  const getSubmenuItem = ({
    hero: { title },
    slug,
  }: ServiceInfo): SubmenuItemInfo => ({
    text: title,
    href: getServiceLink(slug),
  })

  const items: SubmenuItemInfo[] = services.map(getSubmenuItem)

  return <Submenu items={items} buttonText="Services" />
}
