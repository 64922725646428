import React from "react"

import Email from "./Email"
import Name from "./Name"
import MessageField from "./Message"

export default function Fields() {
  return (
    <div>
      <Name />
      <Email />
      <MessageField />
    </div>
  )
}
