import React, { useEffect } from "react"
import styled from "styled-components"

import { SubmenuState, ToggleSubmenuButtonText } from "./types"
import BaseButton from "../../Button"
import { navTextStyles } from "./styles"
import { headerIds, headerClasses } from "../../../constants/header"

const Button = styled(BaseButton)`
  font-weight: 400;
  background-color: transparent;
  ${navTextStyles}
  color: inherit;
`

interface Props extends SubmenuState {
  text: ToggleSubmenuButtonText
}

export default function ToggleSubmenu({ open, close, text, isOpen }: Props) {
  const getClassListArray = (classList?: DOMTokenList): string[] => {
    if (classList) {
      return Array.from(classList)
    }
    return []
  }

  const handleMouseUp = ({ target: { id, classList } }: any) => {
    const classListArray: string[] = getClassListArray(classList)
    const isServicesLink: boolean = id === headerIds.services
    const isNavItem: boolean = classListArray.includes(
      headerClasses.submenuItem
    )
    if (!isServicesLink && !isNavItem && isOpen) {
      close()
    }
  }

  useEffect(() => {
    const eventType = "mouseup"
    document.addEventListener(eventType, handleMouseUp)
    return () => document.removeEventListener(eventType, handleMouseUp)
  }, [isOpen])

  return (
    <Button
      onMouseEnter={open}
      onClick={open}
      id={headerIds.services}
      aria-haspopup
      aria-label="Open services submenu"
    >
      {text}
    </Button>
  )
}
