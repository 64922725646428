import React from "react"
import { Form } from "react-final-form"
import { Config, FormApi, FORM_ERROR, SubmissionErrors } from "final-form"

import Fields from "./Fields"
import SubmitButton from "./SubmitButton"
import FormError from "./FormErrror"
import { fieldNames } from "../../../constants/contact"
import sendContactMessage, { Message } from "../../../api/contact"

type ContactFormConfig = Config<Message>

type FormFieldName = typeof fieldNames[keyof typeof fieldNames]

const ContactForm = () => {
  const makeRequest = async (values: Message): Promise<void> => {
    const response = await sendContactMessage(values)
    if (response.status !== 200) {
      console.error(response)
      throw new Error("Contact request failed")
    }
  }

  const handleError = (error: any) => {
    console.error(error)
    return {
      [FORM_ERROR]:
        "Sorry, there was a problem. Please try contacting us through email at hello@hanso.group instead.",
    }
  }

  const makeRequestAndResetForm = async (
    values: Message,
    form: FormApi<Message, Partial<Message>>
  ): Promise<void> => {
    await makeRequest(values)
    form.reset()
  }

  const onSubmit: ContactFormConfig["onSubmit"] = async (values, form) => {
    try {
      await makeRequestAndResetForm(values, form)
    } catch (error) {
      return handleError(error)
    }
  }

  const addValidationError = (values: Partial<Message>) => {
    return (
      errors: SubmissionErrors,
      name: FormFieldName
    ): SubmissionErrors => {
      if (!values[name]) {
        return {
          ...errors,
          [name]: "This value is required.",
        }
      }
      return errors
    }
  }

  const validate: ContactFormConfig["validate"] = (
    values: Partial<Message>
  ) => {
    const requiredFields: FormFieldName[] = [
      fieldNames.name,
      fieldNames.message,
      fieldNames.email,
    ]
    return requiredFields.reduce(addValidationError(values), {})
  }

  return (
    <Form
      onSubmit={onSubmit}
      validate={validate}
      render={({ handleSubmit, hasSubmitErrors, submitError }) => {
        return (
          <form onSubmit={handleSubmit}>
            {hasSubmitErrors && <FormError>{submitError}</FormError>}
            <Fields />
            <SubmitButton />
          </form>
        )
      }}
    ></Form>
  )
}

export default ContactForm
