import React from "react"
import styled from "styled-components"
import { headerClasses } from "../../../../constants/header"

import { StyledGatsbyLink } from "../../../Link"
import { SubmenuItemInfo, SubmenuState } from "../types"

const ListItem = styled.li`
  width: 100%;

  & + & {
    margin-top: 1.75rem;
  }
`

const Link = styled(StyledGatsbyLink)`
  padding: 0 !important;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.0625rem;
  letter-spacing: -0.0187rem;
  color: black !important;
  text-align: left;
  width: 100%;
`

interface Props extends SubmenuItemInfo {
  close: SubmenuState["close"]
}

export default function SubmenuLink({ href, text, close }: Props) {
  return (
    <ListItem>
      <Link to={href} className={headerClasses.submenuItem} onClick={close}>
        {text}
      </Link>
    </ListItem>
  )
}
