import styled, { css } from "styled-components"
import { Link as GatsbyLink } from "gatsby"

import { styles as buttonStyles } from "./Button"

export const styles = css`
  ${buttonStyles}
  text-align: center;
  text-decoration: none;
`

const Link = styled.a`
  ${styles}
`

export const StyledGatsbyLink = styled(GatsbyLink)`
  ${styles}
`

export default Link
