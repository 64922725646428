import React from "react"
import styled from "styled-components"
import { sectionIds } from "../../constants/sections"
import BaseContentArea from "../ContentArea"

import { SubHeading } from "../styles"

import Form from "./Form"

const ContentArea = styled(BaseContentArea)`
  align-items: center;
  padding: 3rem 2rem;
`

const Body = styled.div`
  max-width: 43.75rem;
  width: 100%;
`

const Title = styled(SubHeading)`
  margin-bottom: 1.75rem;
  text-align: center;
`

const Contact = () => {
  return (
    <ContentArea id={sectionIds.contact}>
      <Body>
        <Title>Contact Us</Title>
        <Form />
      </Body>
    </ContentArea>
  )
}

export default Contact
